var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        { staticClass: "mt-5 pa-0" },
        [
          _c(
            "v-stepper",
            {
              attrs: { vertical: "", color: "primary" },
              model: {
                value: _vm.step,
                callback: function ($$v) {
                  _vm.step = $$v
                },
                expression: "step",
              },
            },
            [
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    color: "primary",
                    complete: _vm.step > 1,
                    step: "1",
                  },
                },
                [
                  _vm._v(" Select "),
                  _vm.step === 1
                    ? _c("small", { staticClass: "mt-1" }, [
                        _vm._v(
                          "Select one or more columns from the selected table"
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _c("v-stepper-content", { attrs: { step: "1" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column" },
                  [
                    _c(
                      "v-row",
                      { attrs: { align: "center" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "2" } },
                          [
                            _c(
                              "v-subheader",
                              { staticClass: "font-weight-bold" },
                              [_vm._v("From")]
                            ),
                          ],
                          1
                        ),
                        _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column justify-start" },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.currentTables,
                                  "item-text": "short_id",
                                  "item-value": "short_id",
                                  label: _vm.fetchingTableColumnsForQuery
                                    ? "Fetching table data"
                                    : "Selected table",
                                  dense: "",
                                  loading:
                                    _vm.fetchingTableColumnsForQuery ||
                                    _vm.tablesFetching,
                                  disabled: _vm.fetchingTableColumnsForQuery,
                                  "hide-details": "",
                                  outlined: "",
                                  "menu-props": { bottom: true, offsetY: true },
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.setTableInRouteQuery($event)
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function (currentTables) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-column py-2",
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "subtitle-2" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    currentTables.item.short_id
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "caption" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    currentTables.item
                                                      .description
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.selectedTable,
                                  callback: function ($$v) {
                                    _vm.selectedTable = $$v
                                  },
                                  expression: "selectedTable",
                                },
                              }),
                              _vm.selectedTable && _vm.selectedTableDescription
                                ? _c(
                                    "v-dialog",
                                    {
                                      attrs: { "max-width": "500" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            color: "info",
                                                            text: "",
                                                            small: "",
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mr-1",
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [_vm._v("info")]
                                                        ),
                                                        _vm._v(
                                                          " About this table "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2523588334
                                      ),
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c("v-card-title", [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "secondary--text",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.selectedTable)
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("v-divider", {
                                            staticClass: "mb-2",
                                          }),
                                          _c("v-card-text", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.selectedTableDescription
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm.fetchingTableColumnsForQuery || _vm.tablesFetching
                      ? _c(
                          "v-row",
                          { staticClass: "my-5", attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              _vm._l(8, function (n) {
                                return _c("v-skeleton-loader", {
                                  key: n,
                                  attrs: { type: "list-item" },
                                })
                              }),
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              _vm._l(8, function (n) {
                                return _c("v-skeleton-loader", {
                                  key: n,
                                  attrs: { type: "list-item" },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm.selectedTable
                      ? [
                          _c(
                            "div",
                            {
                              staticClass:
                                "my-5 d-flex flex-sm-column flex-md-row align-start w-100",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex align-center",
                                  style: _vm.$vuetify.breakpoint.mdAndUp
                                    ? "width:52.5%"
                                    : "width:100%",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      style: _vm.$vuetify.breakpoint.mdAndUp
                                        ? "width:95%"
                                        : "width:100%",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-center justify-space-between flex-wrap w-100",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-bold secondary--text",
                                            },
                                            [
                                              _vm._v(
                                                "Available Columns (" +
                                                  _vm._s(
                                                    _vm.allColumns.length
                                                  ) +
                                                  ")"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                small: "",
                                                disabled:
                                                  _vm.allColumnsAreSelected,
                                                color: "primary",
                                                text: "",
                                                "e2e-snapshot-tables-select-all":
                                                  "",
                                              },
                                              on: { click: _vm.addAllColumns },
                                            },
                                            [_vm._v(" Select all ")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-text-field", {
                                        attrs: {
                                          "hide-details": "",
                                          "append-icon": "search",
                                          label: "Filter",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.availableColumnSearch,
                                          callback: function ($$v) {
                                            _vm.availableColumnSearch = $$v
                                          },
                                          expression: "availableColumnSearch",
                                        },
                                      }),
                                      _c("v-virtual-scroll", {
                                        attrs: {
                                          bench: 2,
                                          items: _vm.columnData,
                                          height: "500",
                                          "item-height": "64",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ item }) {
                                              return [
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    key: item.colName,
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addRemoveColumn(
                                                          item.colName
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex align-center",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.colName
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c(
                                                                  "v-chip",
                                                                  {
                                                                    staticClass:
                                                                      "ml-1",
                                                                    attrs: {
                                                                      outlined:
                                                                        "",
                                                                      color:
                                                                        "secondary",
                                                                      "x-small":
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.type
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-list-item-subtitle",
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.desc)
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-list-item-action",
                                                      [
                                                        _vm.addedColumns.includes(
                                                          item.colName
                                                        )
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "ma-0 pa-0",
                                                                attrs: {
                                                                  color:
                                                                    "green lighten-1",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " check_circle "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.$vuetify.breakpoint.mdAndUp
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "d-flex justify-center",
                                          staticStyle: { width: "5%" },
                                        },
                                        [_c("v-icon", [_vm._v("forward")])],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  class: _vm.$vuetify.breakpoint.smAndDown
                                    ? "mt-8"
                                    : "",
                                  style: _vm.$vuetify.breakpoint.mdAndUp
                                    ? "width:47.5%"
                                    : "width:100%",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-space-between flex-wrap w-100",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-weight-bold secondary--text",
                                        },
                                        [
                                          _vm._v(
                                            "Selected Columns (" +
                                              _vm._s(_vm.addedColumns.length) +
                                              ")"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            small: "",
                                            disabled: !_vm.addedColumns.length,
                                            color: "error",
                                            text: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.addedColumns = []
                                            },
                                          },
                                        },
                                        [_vm._v("Remove all")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-text-field", {
                                    attrs: {
                                      "hide-details": "",
                                      "append-icon": "search",
                                      label: "Filter",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.addedColumnSearch,
                                      callback: function ($$v) {
                                        _vm.addedColumnSearch = $$v
                                      },
                                      expression: "addedColumnSearch",
                                    },
                                  }),
                                  _c("v-virtual-scroll", {
                                    attrs: {
                                      bench: 2,
                                      items: _vm.filteredAddedColumns,
                                      height: "500",
                                      "item-height": "64",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ item }) {
                                          return [
                                            _c(
                                              "v-list-item",
                                              { key: item.colName },
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex align-center",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.colName
                                                              ) +
                                                              " "
                                                          ),
                                                          _c(
                                                            "v-chip",
                                                            {
                                                              staticClass:
                                                                "ml-1",
                                                              attrs: {
                                                                outlined: "",
                                                                color:
                                                                  "secondary",
                                                                "x-small": "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.type
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]),
                                                    _c("v-list-item-subtitle", [
                                                      _vm._v(_vm._s(item.desc)),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item-action",
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          small: "",
                                                          icon: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeColumn(
                                                              item.colName
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color:
                                                                "red lighten-2",
                                                            },
                                                          },
                                                          [_vm._v("clear")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-center" },
                            [
                              _c(
                                "v-dialog",
                                {
                                  attrs: { "max-width": "800" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass: "mr-1",
                                                attrs: {
                                                  disabled:
                                                    !_vm.addedColumns.length ||
                                                    _vm.fetchingTableColumnsForQuery,
                                                  outlined: "",
                                                  text: "",
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                " Preview Selected Columns "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c("v-card-title", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-uppercase secondary--text ml-1",
                                          },
                                          [_vm._v("Selected columns preview")]
                                        ),
                                      ]),
                                      _c("v-divider"),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("SnapshotTablePreview", {
                                            attrs: {
                                              columnsData:
                                                _vm.columnDataForPreview,
                                              headersData:
                                                _vm.columnHeadersForPreview,
                                              fetchingData:
                                                _vm.fetchingPreviewData,
                                              tableName: _vm.selectedTable,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled:
                                      !_vm.selectedTable ||
                                      !_vm.addedColumns.length ||
                                      _vm.fetchingTableColumnsForQuery,
                                    color: "primary",
                                    "e2e-snapshot-tables-continue": "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.step = 2
                                    },
                                  },
                                },
                                [
                                  _vm._v(" Continue "),
                                  _c("v-icon", [_vm._v("navigate_next")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    color: "primary",
                    complete: _vm.step > 2,
                    step: "2",
                  },
                },
                [
                  _vm._v(" Filter "),
                  _vm.step === 2
                    ? _c("small", { staticClass: "mt-1" }, [
                        _vm._v(
                          "Filter on the selected columns for rows that meet certain criteria"
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "2" } },
                [
                  _c(
                    "div",
                    { staticClass: "mb-12" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-3 font-weight-bold",
                          attrs: { text: "", color: "secondary" },
                          on: { click: _vm.addFilter },
                        },
                        [_vm._v("+ add filter")]
                      ),
                      _vm.addedFilters.length
                        ? _c(
                            "div",
                            _vm._l(_vm.addedFilters, function (filter, index) {
                              return _c(
                                "div",
                                { key: filter.index, staticClass: "mb-2 pt-5" },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      model: {
                                        value: _vm.addedFilters[index].valid,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.addedFilters[index],
                                            "valid",
                                            $$v
                                          )
                                        },
                                        expression: "addedFilters[index].valid",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-3",
                                              attrs: {
                                                small: "",
                                                color: "error",
                                                icon: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeFilter(
                                                    filter.index
                                                  )
                                                },
                                              },
                                            },
                                            [_c("v-icon", [_vm._v("clear")])],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            {
                                              attrs: {
                                                align: "end",
                                                justify: "start",
                                                "flex-wrap": "",
                                                "no-gutters": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { cols: "2" },
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      required: "",
                                                      rules: [
                                                        _vm.rules.required,
                                                      ],
                                                      dense: "",
                                                      items: _vm.addedColumns,
                                                      label: "column",
                                                      outlined: "",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.clearFilterDataUponColumnChange(
                                                          index
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.addedFilters[index]
                                                          .column,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.addedFilters[
                                                            index
                                                          ],
                                                          "column",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "addedFilters[index].column",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm.addedFilters[index].column
                                                ? _c(
                                                    "v-col",
                                                    { attrs: { cols: "2" } },
                                                    [
                                                      _c("v-select", {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          dense: "",
                                                          items:
                                                            _vm.columnFilters(
                                                              filter.column
                                                            ),
                                                          label: "condition",
                                                          required: "",
                                                          rules: [
                                                            _vm.rules.required,
                                                          ],
                                                          outlined: "",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.clearFilterFileData(
                                                              index
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.addedFilters[
                                                              index
                                                            ].filter,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.addedFilters[
                                                                index
                                                              ],
                                                              "filter",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "addedFilters[index].filter",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.addedFilters[index].column &&
                                              _vm.addedFilters[index].filter &&
                                              ![
                                                _vm.operatorTypes.NULL_OPERATOR,
                                                _vm.operatorTypes
                                                  .NON_NULL_OPERATOR,
                                              ].includes(
                                                _vm.addedFilters[index].filter
                                              ) &&
                                              !(
                                                _vm.dateAndTimeColumns.includes(
                                                  _vm.colType(
                                                    _vm.addedFilters[index]
                                                      .column
                                                  )
                                                ) &&
                                                _vm.addedFilters[index]
                                                  .filter !==
                                                  _vm.operatorTypes.IN_OPERATOR
                                              ) &&
                                              _vm.addedFilters[index].file ===
                                                null
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: { cols: "2" },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          rules:
                                                            _vm.numericColumns.includes(
                                                              _vm.colType(
                                                                _vm
                                                                  .addedFilters[
                                                                  index
                                                                ].column
                                                              )
                                                            ) &&
                                                            _vm.addedFilters[
                                                              index
                                                            ].filter !==
                                                              _vm.operatorTypes
                                                                .IN_OPERATOR
                                                              ? [
                                                                  _vm.rules
                                                                    .required,
                                                                  _vm.rules
                                                                    .mustBeFloat,
                                                                ]
                                                              : [
                                                                  _vm.rules
                                                                    .required,
                                                                ],
                                                          required: "",
                                                          autocomplete:
                                                            "new-value",
                                                          label:
                                                            _vm.addedFilters[
                                                              index
                                                            ].filter ==
                                                            _vm.operatorTypes
                                                              .IN_OPERATOR
                                                              ? "comma seperated values"
                                                              : "value",
                                                          dense: "",
                                                          outlined: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.addedFilters[
                                                              index
                                                            ].inputField1,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.addedFilters[
                                                                index
                                                              ],
                                                              "inputField1",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "addedFilters[index].inputField1",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.addedFilters[index].column &&
                                              _vm.addedFilters[index].filter &&
                                              _vm.addedFilters[index].filter ===
                                                _vm.operatorTypes
                                                  .BETWEEN_OPERATOR &&
                                              _vm.numericColumns.includes(
                                                _vm.colType(
                                                  _vm.addedFilters[index].column
                                                )
                                              )
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "1",
                                                        outlined: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex align-center justify-center mx-1",
                                                          staticStyle: {
                                                            height: "48px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-uppercase",
                                                            },
                                                            [_vm._v("and")]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.addedFilters[index].column &&
                                              _vm.addedFilters[index].filter &&
                                              _vm.addedFilters[index].filter ===
                                                _vm.operatorTypes
                                                  .BETWEEN_OPERATOR &&
                                              _vm.numericColumns.includes(
                                                _vm.colType(
                                                  _vm.addedFilters[index].column
                                                )
                                              )
                                                ? _c(
                                                    "v-col",
                                                    { attrs: { cols: "2" } },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          required: "",
                                                          rules:
                                                            _vm.numericColumns.includes(
                                                              _vm.colType(
                                                                _vm
                                                                  .addedFilters[
                                                                  index
                                                                ].column
                                                              )
                                                            ) &&
                                                            _vm.addedFilters[
                                                              index
                                                            ].filter !==
                                                              _vm.operatorTypes
                                                                .IN_OPERATOR
                                                              ? [
                                                                  _vm.rules
                                                                    .required,
                                                                  _vm.rules
                                                                    .mustBeFloat,
                                                                ]
                                                              : [
                                                                  _vm.rules
                                                                    .required,
                                                                ],
                                                          label: "value",
                                                          dense: "",
                                                          outlined: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.addedFilters[
                                                              index
                                                            ].inputField2,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.addedFilters[
                                                                index
                                                              ],
                                                              "inputField2",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "addedFilters[index].inputField2",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.addedFilters[index].column &&
                                              _vm.addedFilters[index].filter &&
                                              _vm.addedFilters[index].filter ==
                                                _vm.operatorTypes.IN_OPERATOR
                                                ? _c(
                                                    "v-col",
                                                    { attrs: { cols: "1" } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex align-center",
                                                        },
                                                        [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            _vm._g(
                                                                              {
                                                                                staticClass:
                                                                                  "d-flex align-center",
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-file-input",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      dense:
                                                                                        "",
                                                                                      accept:
                                                                                        ".txt",
                                                                                      label:
                                                                                        "Upload file",
                                                                                      "hide-input":
                                                                                        _vm
                                                                                          .addedFilters[
                                                                                          index
                                                                                        ]
                                                                                          .file ===
                                                                                        null,
                                                                                      outlined:
                                                                                        "",
                                                                                    },
                                                                                  on: {
                                                                                    change:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.uploadFile(
                                                                                          $event,
                                                                                          index
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .addedFilters[
                                                                                          index
                                                                                        ]
                                                                                          .file,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm
                                                                                              .addedFilters[
                                                                                              index
                                                                                            ],
                                                                                            "file",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "addedFilters[index].file",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Upload a plain text file (.txt), having one unquoted value per line."
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          filter.fileError
                                                            ? _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    bottom: "",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "activator",
                                                                          fn: function ({
                                                                            on,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "v-icon",
                                                                                _vm._g(
                                                                                  {
                                                                                    staticClass:
                                                                                      "ml-1",
                                                                                    attrs:
                                                                                      {
                                                                                        color:
                                                                                          "error",
                                                                                      },
                                                                                  },
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _vm._v(
                                                                                    "info"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "File parsing error."
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.addedFilters[index].column &&
                                              _vm.addedFilters[index].filter &&
                                              ![
                                                _vm.operatorTypes.NULL_OPERATOR,
                                                _vm.operatorTypes
                                                  .NON_NULL_OPERATOR,
                                                _vm.operatorTypes.IN_OPERATOR,
                                              ].includes(
                                                _vm.addedFilters[index].filter
                                              ) &&
                                              _vm.dateColumns.includes(
                                                _vm.colType(
                                                  _vm.addedFilters[index].column
                                                )
                                              )
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: { cols: "1" },
                                                    },
                                                    [
                                                      _c(
                                                        "v-menu",
                                                        {
                                                          attrs: {
                                                            "close-on-content-click": false,
                                                            transition:
                                                              "scale-transition",
                                                            "offset-y": "",
                                                            "min-width":
                                                              "290px",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      _vm._g(
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm
                                                                                  .addedFilters[
                                                                                  index
                                                                                ]
                                                                                  .filter ===
                                                                                _vm
                                                                                  .operatorTypes
                                                                                  .BETWEEN_OPERATOR
                                                                                  ? "Start date"
                                                                                  : "date",
                                                                              readonly:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              rules:
                                                                                [
                                                                                  _vm
                                                                                    .rules
                                                                                    .required,
                                                                                ],
                                                                              required:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .addedFilters[
                                                                                  index
                                                                                ]
                                                                                  .datePicker1,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .addedFilters[
                                                                                      index
                                                                                    ],
                                                                                    "datePicker1",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "addedFilters[index].datePicker1",
                                                                            },
                                                                        },
                                                                        on
                                                                      )
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("v-date-picker", {
                                                            attrs: {
                                                              "no-title": "",
                                                              scrollable: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .addedFilters[
                                                                  index
                                                                ].datePicker1,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .addedFilters[
                                                                      index
                                                                    ],
                                                                    "datePicker1",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "addedFilters[index].datePicker1",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.addedFilters[index].column &&
                                              _vm.addedFilters[index].filter &&
                                              ![
                                                _vm.operatorTypes.NULL_OPERATOR,
                                                _vm.operatorTypes
                                                  .NON_NULL_OPERATOR,
                                                _vm.operatorTypes.IN_OPERATOR,
                                              ].includes(
                                                _vm.addedFilters[index].filter
                                              ) &&
                                              _vm.timeColumns.includes(
                                                _vm.colType(
                                                  _vm.addedFilters[index].column
                                                )
                                              )
                                                ? _c(
                                                    "v-col",
                                                    { attrs: { cols: "1" } },
                                                    [
                                                      _c(
                                                        "v-menu",
                                                        {
                                                          attrs: {
                                                            "close-on-content-click": false,
                                                            transition:
                                                              "scale-transition",
                                                            "offset-y": "",
                                                            "min-width":
                                                              "290px",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      _vm._g(
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm
                                                                                  .addedFilters[
                                                                                  index
                                                                                ]
                                                                                  .filter ===
                                                                                _vm
                                                                                  .operatorTypes
                                                                                  .BETWEEN_OPERATOR
                                                                                  ? "Start time"
                                                                                  : "time",
                                                                              readonly:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              rules:
                                                                                [
                                                                                  _vm
                                                                                    .rules
                                                                                    .required,
                                                                                ],
                                                                              required:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .addedFilters[
                                                                                  index
                                                                                ]
                                                                                  .timePicker1,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .addedFilters[
                                                                                      index
                                                                                    ],
                                                                                    "timePicker1",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "addedFilters[index].timePicker1",
                                                                            },
                                                                        },
                                                                        on
                                                                      )
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "px-2",
                                                            },
                                                            [
                                                              _c(
                                                                "time-picker",
                                                                {
                                                                  attrs: {
                                                                    "use-seconds":
                                                                      "",
                                                                    format:
                                                                      "24hr",
                                                                    scrollable:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .addedFilters[
                                                                        index
                                                                      ]
                                                                        .timePicker1,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .addedFilters[
                                                                            index
                                                                          ],
                                                                          "timePicker1",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "addedFilters[index].timePicker1",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.addedFilters[index].column &&
                                              _vm.addedFilters[index].filter &&
                                              _vm.dateAndTimeColumns.includes(
                                                _vm.colType(
                                                  _vm.addedFilters[index].column
                                                )
                                              ) &&
                                              _vm.addedFilters[index].filter ===
                                                _vm.operatorTypes
                                                  .BETWEEN_OPERATOR
                                                ? _c(
                                                    "v-col",
                                                    { attrs: { cols: "1" } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex align-center justify-center mx-1",
                                                          staticStyle: {
                                                            height: "48px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-uppercase",
                                                            },
                                                            [_vm._v("and")]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.addedFilters[index].column &&
                                              _vm.addedFilters[index].filter &&
                                              _vm.dateColumns.includes(
                                                _vm.colType(
                                                  _vm.addedFilters[index].column
                                                )
                                              ) &&
                                              _vm.addedFilters[index].filter ===
                                                _vm.operatorTypes
                                                  .BETWEEN_OPERATOR
                                                ? _c(
                                                    "v-col",
                                                    { attrs: { cols: "1" } },
                                                    [
                                                      _c(
                                                        "v-menu",
                                                        {
                                                          attrs: {
                                                            "close-on-content-click": false,
                                                            transition:
                                                              "scale-transition",
                                                            "offset-y": "",
                                                            "min-width":
                                                              "290px",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      _vm._g(
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "End date",
                                                                              readonly:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              rules:
                                                                                [
                                                                                  _vm
                                                                                    .rules
                                                                                    .required,
                                                                                ],
                                                                              required:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .addedFilters[
                                                                                  index
                                                                                ]
                                                                                  .datePicker2,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .addedFilters[
                                                                                      index
                                                                                    ],
                                                                                    "datePicker2",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "addedFilters[index].datePicker2",
                                                                            },
                                                                        },
                                                                        on
                                                                      )
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("v-date-picker", {
                                                            attrs: {
                                                              "no-title": "",
                                                              scrollable: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .addedFilters[
                                                                  index
                                                                ].datePicker2,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .addedFilters[
                                                                      index
                                                                    ],
                                                                    "datePicker2",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "addedFilters[index].datePicker2",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.addedFilters[index].column &&
                                              _vm.addedFilters[index].filter &&
                                              _vm.timeColumns.includes(
                                                _vm.colType(
                                                  _vm.addedFilters[index].column
                                                )
                                              ) &&
                                              _vm.addedFilters[index].filter ===
                                                _vm.operatorTypes
                                                  .BETWEEN_OPERATOR
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "ml-2",
                                                      attrs: { cols: "1" },
                                                    },
                                                    [
                                                      _c(
                                                        "v-menu",
                                                        {
                                                          attrs: {
                                                            "close-on-content-click": false,
                                                            transition:
                                                              "scale-transition",
                                                            "offset-y": "",
                                                            "min-width":
                                                              "290px",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      _vm._g(
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "End time",
                                                                              readonly:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              rules:
                                                                                [
                                                                                  _vm
                                                                                    .rules
                                                                                    .required,
                                                                                ],
                                                                              required:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .addedFilters[
                                                                                  index
                                                                                ]
                                                                                  .timePicker2,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .addedFilters[
                                                                                      index
                                                                                    ],
                                                                                    "timePicker2",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "addedFilters[index].timePicker2",
                                                                            },
                                                                        },
                                                                        on
                                                                      )
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "px-2",
                                                            },
                                                            [
                                                              _c(
                                                                "time-picker",
                                                                {
                                                                  attrs: {
                                                                    "use-seconds":
                                                                      "",
                                                                    format:
                                                                      "24hr",
                                                                    scrollable:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .addedFilters[
                                                                        index
                                                                      ]
                                                                        .timePicker2,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .addedFilters[
                                                                            index
                                                                          ],
                                                                          "timePicker2",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "addedFilters[index].timePicker2",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled:
                          !!_vm.addedFilters.length && !_vm.areFiltersValid,
                        color: "primary",
                        "e2e-snapshot-tables-skip-step-2": "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.step = 3
                        },
                      },
                    },
                    [
                      !!_vm.addedFilters.length
                        ? _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _vm._v(" Continue "),
                              _c("v-icon", [_vm._v("navigate_next")]),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _vm._v(" Skip "),
                              _c("v-icon", [_vm._v("skip_next")]),
                            ],
                            1
                          ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.step = 1
                        },
                      },
                    },
                    [_vm._v("Back")]
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    color: "primary",
                    complete: _vm.step > 3,
                    step: "3",
                  },
                },
                [
                  _vm._v(" Aggregate "),
                  _vm.step === 3
                    ? _c("small", { staticClass: "mt-1" }, [
                        _vm._v(
                          "Aggregate values in selected columns, forming groups based on the values of other columns"
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "3" } },
                [
                  _c(
                    "div",
                    { staticClass: "mb-12" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-3 font-weight-bold",
                          attrs: { text: "", color: "secondary" },
                          on: { click: _vm.addAggregationCondition },
                        },
                        [_vm._v("+ Add Aggregation Function")]
                      ),
                      _vm.addedAggregationConditions.length
                        ? _c(
                            "div",
                            _vm._l(
                              _vm.addedAggregationConditions,
                              function (aggregator, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "d-flex align-center",
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "error", icon: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeAggregationCondition(
                                              aggregator.index
                                            )
                                          },
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("clear")])],
                                      1
                                    ),
                                    _c(
                                      "v-form",
                                      {
                                        model: {
                                          value: aggregator.valid,
                                          callback: function ($$v) {
                                            _vm.$set(aggregator, "valid", $$v)
                                          },
                                          expression: "aggregator.valid",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            attrs: {
                                              align: "center",
                                              "no-gutters": "",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "mr-2",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    label: "Function",
                                                    items:
                                                      _vm.filteredAggregationFunctions,
                                                    required: "",
                                                    rules: [_vm.rules.required],
                                                    outlined: "",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      aggregator.aggregationColumns =
                                                        _vm.getAggregationColumnNames(
                                                          aggregator.aggFunction
                                                        )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      aggregator.aggFunction,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        aggregator,
                                                        "aggFunction",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "aggregator.aggFunction",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "4" } },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    label: "Column",
                                                    items:
                                                      aggregator.aggregationColumns,
                                                    required: "",
                                                    rules: [_vm.rules.required],
                                                    outlined: "",
                                                  },
                                                  model: {
                                                    value: aggregator.column,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        aggregator,
                                                        "column",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "aggregator.column",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "1" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-center text-uppercase",
                                                  },
                                                  [_vm._v("as")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "3" } },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Optional alias..",
                                                    rules: [
                                                      _vm.rules
                                                        .aliasNameDifferentThanColumNames,
                                                      _vm.rules
                                                        .uniqueAggregationAliasName,
                                                    ],
                                                    outlined: "",
                                                  },
                                                  model: {
                                                    value: aggregator.alias,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        aggregator,
                                                        "alias",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "aggregator.alias",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-6" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-bold",
                          attrs: {
                            disabled:
                              !_vm.addedAggregationConditions.length ||
                              !_vm.validAggregationConditions.length ||
                              _vm.onlyAggregationWithoutGroupBy,
                            text: "",
                            color: "secondary",
                          },
                          on: { click: _vm.addAggregationFilter },
                        },
                        [_vm._v(" + Add Aggregation Filter ")]
                      ),
                      _vm._l(
                        _vm.addedAggregationFilters,
                        function (aggregationFilter, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "d-flex align-center" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "error", icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeAggregationFilter(
                                        aggregationFilter.index
                                      )
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("clear")])],
                                1
                              ),
                              _c(
                                "v-form",
                                {
                                  model: {
                                    value: aggregationFilter.valid,
                                    callback: function ($$v) {
                                      _vm.$set(aggregationFilter, "valid", $$v)
                                    },
                                    expression: "aggregationFilter.valid",
                                  },
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        "no-gutters": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              label: "Column",
                                              items:
                                                _vm.validAggregationConditions,
                                              outlined: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                aggregationFilter.valueType =
                                                  _vm.getAggregationFilterColumnType(
                                                    aggregationFilter.column
                                                  )
                                              },
                                            },
                                            model: {
                                              value: aggregationFilter.column,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  aggregationFilter,
                                                  "column",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "aggregationFilter.column",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mx-2",
                                          attrs: { cols: "3" },
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              label: "Filter",
                                              items: Object.keys(
                                                _vm.aggregationFilterOperators
                                              ),
                                              required: "",
                                              rules: [_vm.rules.required],
                                              outlined: "",
                                            },
                                            model: {
                                              value: aggregationFilter.filter,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  aggregationFilter,
                                                  "filter",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "aggregationFilter.filter",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "2" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              required: "",
                                              rules:
                                                _vm.numericColumns.includes(
                                                  aggregationFilter.valueType
                                                ) ||
                                                /^(COUNT|AVG)\(.*/.test(
                                                  aggregationFilter.column
                                                )
                                                  ? [
                                                      _vm.rules.required,
                                                      _vm.rules.mustBeFloat,
                                                    ]
                                                  : [_vm.rules.required],
                                              outlined: "",
                                            },
                                            model: {
                                              value: aggregationFilter.value,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  aggregationFilter,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "aggregationFilter.value",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled:
                          (!!_vm.addedAggregationConditions.length &&
                            !_vm.areAggregationConditionsValid) ||
                          (!!_vm.addedAggregationFilters.length &&
                            !_vm.areAggregationFiltersValid),
                        color: "primary",
                        "e2e-snapshot-tables-skip-step-3": "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.step = 4
                        },
                      },
                    },
                    [
                      !!_vm.addedAggregationConditions.length
                        ? _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _vm._v(" Continue "),
                              _c("v-icon", [_vm._v("navigate_next")]),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _vm._v(" Skip "),
                              _c("v-icon", [_vm._v("skip_next")]),
                            ],
                            1
                          ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.step = 2
                        },
                      },
                    },
                    [_vm._v("Back")]
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    color: "primary",
                    complete: _vm.step > 4,
                    step: "4",
                  },
                },
                [
                  _vm._v(" Sort "),
                  _vm.step === 4
                    ? _c("small", { staticClass: "mt-1" }, [
                        _vm._v(
                          "Sort data in either ascending or descending according to one or more columns"
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "4" } },
                [
                  _c(
                    "div",
                    { staticClass: "mb-12" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-3 font-weight-bold",
                          attrs: {
                            disabled: _vm.onlyAggregationWithoutGroupBy,
                            text: "",
                            color: "secondary",
                          },
                          on: { click: _vm.addOrderingCondition },
                        },
                        [_vm._v(" + Add Ordering Column ")]
                      ),
                      _vm.addedOrderingConditions.length
                        ? _c(
                            "div",
                            _vm._l(
                              _vm.addedOrderingConditions,
                              function (order, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "d-flex align-center",
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "error", icon: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeOrderingCondition(
                                              order.index
                                            )
                                          },
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("clear")])],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { align: "center" } },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "v-form",
                                              {
                                                model: {
                                                  value: order.valid,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      order,
                                                      "valid",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "order.valid",
                                                },
                                              },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    items:
                                                      _vm.orderByColumnNames,
                                                    label: "Columns",
                                                    required: "",
                                                    rules: [_vm.rules.required],
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "prepend-item",
                                                        fn: function () {
                                                          return [
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "px-4",
                                                              attrs: {
                                                                autofocus: "",
                                                                label: "Filter",
                                                                outlined: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.orderBySearch,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.orderBySearch =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "orderBySearch",
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value: order.column,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        order,
                                                        "column",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "order.column",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-col", { attrs: { cols: "6" } }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                            },
                                            [
                                              _c(
                                                "v-radio-group",
                                                {
                                                  attrs: { row: "" },
                                                  model: {
                                                    value: order.order,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        order,
                                                        "order",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "order.order",
                                                  },
                                                },
                                                [
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: "Ascending",
                                                      value: "ASC",
                                                    },
                                                  }),
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: "Descending",
                                                      value: "DESC",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled:
                          !!_vm.addedOrderingConditions.length &&
                          !_vm.areOrderingConditionsValid,
                        color: "primary",
                        "e2e-snapshot-tables-skip-step-4": "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.step = 5
                        },
                      },
                    },
                    [
                      !!_vm.addedOrderingConditions.length
                        ? _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _vm._v(" Continue "),
                              _c("v-icon", [_vm._v("navigate_next")]),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _vm._v(" Skip "),
                              _c("v-icon", [_vm._v("skip_next")]),
                            ],
                            1
                          ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.step = 3
                        },
                      },
                    },
                    [_vm._v("Back")]
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    color: "primary",
                    complete: _vm.step > 5,
                    step: "5",
                  },
                },
                [
                  _vm._v(" Row limit "),
                  _vm.step === 5
                    ? _c("small", { staticClass: "mt-1" }, [
                        _vm._v("Specify the number of records to return"),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "5" } },
                [
                  _c(
                    "v-form",
                    {
                      staticClass: "my-3",
                      model: {
                        value: _vm.validRowNumberForm,
                        callback: function ($$v) {
                          _vm.validRowNumberForm = $$v
                        },
                        expression: "validRowNumberForm",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { "max-width": "300px" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: _vm.onlyAggregationWithoutGroupBy,
                              outlined: "",
                              dense: "",
                              required: "",
                              rules: [
                                _vm.rules.required,
                                _vm.rules.maxAndIntQueryRows,
                              ],
                            },
                            model: {
                              value: _vm.numberRows,
                              callback: function ($$v) {
                                _vm.numberRows = $$v
                              },
                              expression: "numberRows",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.validRowNumberForm,
                        color: "primary",
                        "e2e-snapshot-tables-continue-step-5": "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.step = 6
                        },
                      },
                    },
                    [
                      _vm._v(" Continue "),
                      _c("v-icon", [_vm._v("navigate_next")]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.step = 4
                        },
                      },
                    },
                    [_vm._v("Back")]
                  ),
                ],
                1
              ),
              _c("v-stepper-step", { attrs: { color: "primary", step: "6" } }, [
                _vm._v(" Final query "),
                _vm.step === 6
                  ? _c("small", { staticClass: "mt-1" }, [
                      _vm._v("Review the final query before submitting it"),
                    ])
                  : _vm._e(),
              ]),
              _c("v-stepper-content", { attrs: { step: "6" } }, [
                _vm.step === 6
                  ? _c("div", { staticClass: "d-flex flex-column" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "max-width": "600px",
                            "border-style": "solid",
                            "border-width": "thin",
                          },
                        },
                        [
                          _c("CodeHighlight", {
                            attrs: { language: "sql", code: _vm.query },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex align-center mt-3" },
                        [
                          _c("QuerySubmit", {
                            staticClass: "mr-1",
                            attrs: {
                              mode: _vm.queryModeOptions.FULL_WITH_SAVE_MODE,
                              buttonName: "submit for data export",
                              iconShaped: false,
                              forDialog: true,
                              query: _vm.query,
                            },
                            on: { success: _vm.querySubmitted },
                          }),
                          _c("GetTableData", { attrs: { sqlText: _vm.query } }),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "" },
                              on: {
                                click: function ($event) {
                                  _vm.step = 5
                                },
                              },
                            },
                            [_vm._v("Back")]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }