var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-input",
    { attrs: { "hide-details": "" } },
    [
      _c(
        "v-icon",
        { staticClass: "mt-1", attrs: { slot: "prepend" }, slot: "prepend" },
        [_vm._v("mdi-clock-time-four-outline")]
      ),
      _c(
        "v-container",
        { staticClass: "pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.hours,
                      "item-disabled": "disabled",
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                    },
                    on: { input: _vm.handleInput },
                    model: {
                      value: _vm.hour,
                      callback: function ($$v) {
                        _vm.hour = $$v
                      },
                      expression: "hour",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.minutes,
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                    },
                    on: { input: _vm.handleInput },
                    model: {
                      value: _vm.minute,
                      callback: function ($$v) {
                        _vm.minute = $$v
                      },
                      expression: "minute",
                    },
                  }),
                ],
                1
              ),
              _vm.useSeconds
                ? _c(
                    "v-col",
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.seconds,
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                        },
                        on: { input: _vm.handleInput },
                        model: {
                          value: _vm.second,
                          callback: function ($$v) {
                            _vm.second = $$v
                          },
                          expression: "second",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.meridiem
                ? _c(
                    "v-col",
                    [
                      _c("v-select", {
                        attrs: {
                          items: ["AM", "PM"],
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                        },
                        on: { input: _vm.handleInput },
                        model: {
                          value: _vm.ampm,
                          callback: function ($$v) {
                            _vm.ampm = $$v
                          },
                          expression: "ampm",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }